require('./core/bootstrap');

choicesConfig = {
    loadingText: 'Laden...',
    noResultsText: 'Geen resultaten',
    noChoicesText: 'Geen opties',
    itemSelectText: '',
    uniqueItemText: 'Item is al geselecteerd',
    customAddItemText: 'Ingevulde waarde moet aan specifieke eisen voldoen',
    addItemText: (value) => {
        return `Druk op ENTER om <b>"${value}"</b> toe te voegen`;
    },
    maxItemText: (maxItemCount) => {
        return `Maximum van ${maxItemCount} items bereikt`;
    },
}
window.choicesInstances = {};
window.initChoices = function (el) {
    if (choicesInstances[el.name]) {
        choicesInstances[el.name].destroy();
    }

    if (el.nodeName == 'SELECT' && !el.multiple) { //regular select
        choicesInstances[el.name] = new Choices(el, Object.assign(choicesConfig, {
            itemSelectText: '',
            shouldSort: false
        }));
        return choicesInstances[el.name];

    } else if (el.nodeName == 'SELECT' && el.multiple) { //multiselect
        choicesInstances[el.name] = new Choices(el, Object.assign(choicesConfig, {
            removeItemButton: true,
            itemSelectText: '',
            placeholderValue: 'Items toevoegen',
            shouldSort: false
        }));
        return choicesInstances[el.name];

    } else if (el.nodeName == 'INPUT' && el.type == 'text') { //delimited text input
        choicesInstances[el.name] = new Choices(el, Object.assign(choicesConfig, {
            removeItemButton: true,
            itemSelectText: '',
            placeholderValue: 'Items toevoegen',
            shouldSort: false,
            delimiter: el.dataset.delimiter,
            duplicateItemsAllowed: false
        }));
        return choicesInstances[el.name];

    } else {
        console.log('unknown choices element type');
        return false;
    }
}
require('./core/vue');
Array.from(document.getElementsByClassName('choices-select-one')).forEach(element => {
    initChoices(element);
});
Array.from(document.getElementsByClassName('choices-select-multiple')).forEach(element => {
    initChoices(element);
});
Array.from(document.getElementsByClassName('choices-text')).forEach(element => {
    initChoices(element);
});


//Standard options for datatables
$.extend($.fn.dataTable.defaults, {
    "lengthMenu": [10, 25, 50, { label: 'All', value: -1 }],
    "pageLength": 25,
    "order": [],
    "pagingType": "full_numbers",
    responsive: true,
    language: {
        emptyTable: "Geen resultaten",
        search: "_INPUT_",
        searchPlaceholder: "Zoeken",
        info: "_START_ - _END_ van _TOTAL_ resultaten",
        paginate: {
            first: "<<",
            last: ">>",
            next: ">",
            previous: "<",
        },
        lengthMenu: "_MENU_ resultaten per pagina",
        info: "_START_ - _END_ van _TOTAL_ resultaten",
        infoEmpty: "0 - 0 van 0 resultaten",
        zeroRecords: "Geen resultaten gevonden",
        infoFiltered: "(gefilterd van _MAX_ resultaten)",
        deferRender: true
    }
});

window.createDataTable = function (selector, collapsePosition = -2) {
    $(selector).each(function() {
        $(this).find('tbody tr').each(function() {
            $(this).find('td').eq(collapsePosition).after('<td class="table-col-fit-content"></td>');
        });
        $(this).find('thead tr th').eq(collapsePosition).after('<th class="table-col-fit-content dt-collapse-head"></th>');

        let targetsArray = [];
        for (let i = 0; i < Math.abs(collapsePosition + 1); i++) {
            targetsArray.push(-(i + 1));
        }

        $(this).DataTable({
            columnDefs: [
                {
                    className: 'dtr-control arrow-right',
                    orderable: false,
                    responsivePriority: 5,
                    target: collapsePosition,
                    title: '',
                    data: '',
                    defaultContent: '',
                    searchable: false,
                    width: '30px'
                },
                {
                    responsivePriority: 2,
                    targets: targetsArray
                }
            ],
            responsive: {
                details: {
                    type: 'column',
                    target: collapsePosition
                }
            },
        });
    });
}


$(document).ready(function() {
    createDataTable('.datatables');

    //Show confirm dialog if delete button
    $('.delete-alert').click(function(e) {
        e.preventDefault();
        Swal.fire({
            title: 'Weet je zeker dat je deze wilt verwijderen?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00B0CA',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Doorgaan',
            cancelButtonText: 'Annuleren'
        }).then((result) => {
            if (result.value) {
                $(this).closest('form').submit();
            }
        });
    });

    //Show google information in 'Meta informatie' on moduleitems and pages
    $("#meta_titel").keyup(function() {
        let metaTitel = $(this).val()
        $("#google-header").text(metaTitel);
        $("#meta-titel-count").text(metaTitel.length);
    });

    $("#meta_description").keyup(function() {
        let metaDescription = $(this).val();
        $("#google-description").text(metaDescription);
        $("#meta-description-count").text(metaDescription.length);
        if (metaDescription.length > 252) {
            $("#meta-description-count").removeClass('text-warning');
            $("#meta-description-count").addClass('text-danger');
        } else if (metaDescription.length > 230) {
            $("#meta-description-count").removeClass('text-danger');
            $("#meta-description-count").addClass('text-warning');
        } else {
            $("#meta-description-count").removeClass('text-danger');
            $("#meta-description-count").removeClass('text-warning');
        }
    });

    $("#slug").keyup(function() {
        $("#google-slug").text('/' + slugify($(this).val()));
    });

    //Builder options
    $(".builder__item__select").change(function() {
        changeColToNumber(this, $(this).val());
    });

    //Save form via top button
    $("#saveForm").click(function() {
        setTimeout(function() {
            $("button[type=submit]").trigger('click');
        }, 200);
    })
    $("#saveFormAndStay").click(function() {
        setTimeout(function() {
            $("button[type=submit][name=stay_on_page]").trigger('click');
        }, 200);
    })

    //Add input fields to ckeditor in form module 'E-mail inhoud'
    $(document).on("click", ".add-inputfield", function(e) {
        e.preventDefault();
        let variable = $(this).html();
        CKEDITOR.instances['content'].insertText(variable)
    });

    //For custom file input to work
    var uploaders = Array.from(document.getElementsByClassName('crob-file-uploader__toggler'));
    uploaders.forEach(control => {
        let controlDefValue = control.value
        let input = document.getElementById(control.dataset.input);
        control.addEventListener('click', function () {
            input.click();
        })
        input.addEventListener('change', function () {
            if (input.value) {
                control.value = input.value.replace(/^.*[\\\/]/, '');
            } else {
                control.value = controlDefValue;
            }
        })
    });
});

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

//process files from elfinder popup
window.processSelectedFile = function(filePath, requestingField) {
    $('.modal').modal('hide');
    $(`#image-text-input-${requestingField}`).val(`/${filePath}`).trigger('change');
    $(`#image-input-${requestingField}`).attr('src', `/${filePath}`);
    $(`#image-elfinder-input-${requestingField}`).css('background-image', "url('/" + filePath + "')");
    document.getElementById(`image-iframe-${requestingField}`).contentDocument.location.reload(true);
}

//Clear image field input
window.clearImageAttribute = (elem) => {
    let parent = $(elem).closest('.image-attribute');
    parent.find('.image-attribute__input').val('');
    parent.find('.image-attribute__image').css('background-image', '/vendor/robreclame/images/no-image.png');
}

//Clear document field input
window.clearDocumentField = (elem) => {
    $(elem).closest('.form-group').find('.file-browser-input').val('');
}

//Function to hide show components based on value of select
window.showHideComponents = function(elem) {
    let divToShow = $(elem).val();

    //Hide other divs
    $('.showhide').hide();
    $('.showhide').find('input').prop("disabled", true);
    $('.showhide').find('select').prop("disabled", true);
    $('.showhide').find('select').each(function (index, element) {
        choicesInstances[element.name].disable();
    });

    //Show corresponding div
    $(`.showhide-${divToShow}`).show();
    $(`.showhide-${divToShow}`).find('input').prop("disabled", false);
    $(`.showhide-${divToShow}`).find('select').prop("disabled", false);
    $(`.showhide-${divToShow}`).find('select').each(function (index, element) {
        choicesInstances[element.name].destroy();
        initChoices(element);
    });
}

//Function to hide show components bases on true / false
window.showHideComponentsBoolean = function(elem) {
    let divToShow = $(elem).attr('name');

    //Hide other divs
    $('.sh-boolean').hide();
    $('.sh-boolean').find('input').prop("disabled", true);
    $('.sh-boolean').find('select').prop("disabled", true);

    //Show corresponding div
    if ($(elem).val() == 1) {
        $(`.sh-boolean-${divToShow}`).show();
        $(`.sh-boolean-${divToShow}`).find('input').prop("disabled", false);
        $(`.sh-boolean-${divToShow}`).find('select').prop("disabled", false);
    }
}

//Function to hide show components with classname based on value of select
window.showHideByClass = function(elem, className, valueToShow) {
    let value = $(elem).val();

    //Show corresponding div by class
    if (value == valueToShow) {
        $(`.sh-class-${className}`).show();
        $(`.sh-class-${className}`).find('input').prop("disabled", false);
        $(`.sh-class-${className}`).find('select').prop("disabled", false);
    }

    //Hide corresponding div by class
    if (value != valueToShow) {
        $(`.sh-class-${className}`).hide();
        $(`.sh-class-${className}`).find('input').prop("disabled", true);
        $(`.sh-class-${className}`).find('select').prop("disabled", true);
    }
}

//Add repeatable fields
// window.addRepeatable = function(elem) {
//     let clone = $(elem).parent().find('.repeatable:first').clone();

//     clone.find('.bootstrap-select').replaceWith(function() { return $('select', this); });
//     clone.find('button').attr('onclick', 'deleteRepeatable(this)').prop('disabled', false);

//     if (clone.find('input').attr('name')) {
//         clone.find('input').val('');

//         //change duplicated name to unique non numeric name
//         let oldInputName = clone.find('input').attr('name');
//         let newInputName = getUniqueName(oldInputName, 1);
//         clone.find('input').attr('name', newInputName);
//     }

//     if (clone.find('select')) {
//         clone.find('select').val('');
//     }

//     $(elem).parent().find('.repeatable-holder').append(clone);

//     // $('.selectpicker').selectpicker('refresh');
// }

// window.deleteRepeatable = function(elem) {
//     $(elem).closest('.repeatable').remove();
// }

// window.clearRepeatable = function(elem) {
//     $(elem).parent('.repeatable').find('select').prop("selectedIndex", 0);
//     // $('.selectpicker').selectpicker('refresh');
// }

// Lists
window.addListRow = function(elem) {
    let clone = $(elem).parent().find('.list-row:last').clone();

    if (clone.find('input').attr('name')) {
        clone.find('input').val('');
        clone.find('input').prop("readonly", false);

        let maxId = 1;
        $(elem).parent().find('.list-row').each(function() {
            maxId = Math.max(parseInt($(this).attr("data-id")), maxId);
        });
        let key = maxId + 1;
        clone.attr('data-id', key);
        
        jQuery.each(clone.find('input'), (i, element) => {
            let newName = getUniqueName($(element).attr('name'), 1, key);

            // transform file inputs for link-list attribute
            if ($(element).hasClass('file-browser-input')) {
                let oldSlug = slugify($(element).attr('name'));
                let newSlug = slugify(newName);

                $(element).attr('id', 'image-text-input-'+newSlug);
                clone.find('.file-browser-button').attr('data-bs-target', '#image-modal-'+newSlug);
			    clone.find('#image-modal-'+oldSlug).attr('id', 'image-modal-'+newSlug);
			    clone.find('#image-iframe-'+oldSlug).attr('id', 'image-iframe-'+newSlug).attr('src', '/elfinder/popup/'+newSlug);
			    // clone.find('#image-iframe-'+oldSlug).attr('src', '/elfinder/popup/'+newSlug);
            }

            $(element).attr('name', newName);
        });
    }

    $(elem).parent().find('.sortable').append(clone);
}

window.deleteListRow = function(elem) {
    $(elem).closest('.list-row').remove();
}

window.slugifyUnderscore = function(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '_') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\_\_+/g, '_') // Replace multiple - with single -
        .replace(/^_+/, '') // Trim - from start of text
        .replace(/_+$/, ''); // Trim - from end of text
}

window.slugify = function(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}

window.changeColToNumber = function(elem, number) {
    for (var i = 2; i < 13; i++) {
        $(elem).closest('.builder__item').removeClass('col-' + i);
    }
    $(elem).val(number);
    $(elem).closest('.builder__item').addClass('col-' + number);
}

window.notify = function(message, type) {
    $.notify(message, {
        className: type,
        timer: 4000,
        position: 'top center'
    });
}

window.getUniqueName = function(oldName, position, key = getUniqid()) {
    let newName = oldName.split("[");
    // newName[position] = getUniqid() + ']';
    newName[position] = key + ']';
    newName = newName.join('[');
    return newName
}

window.getUniqid = function() {
    var ts = String(new Date().getTime()),
        i = 0,
        out = '';
    for (i = 0; i < ts.length; i += 2) {
        out += Number(ts.substr(i, 2)).toString(36);
    }
    return ('d' + out);
}