<template>
    <select ref="select" class="form-control" :name="name" :value="modelValue"  @change="$emit('update:modelValue', $event.target.value)">
        <slot></slot>
        <!-- <option v-for="option,id in options" :value="id" :key="id">{{ option }}</option> -->
    </select>
</template>

<script>
	// import Choices from 'choices.js';
	export default {
		props: ['options', 'name', 'modelValue'],
		emits: ['update:modelValue'],
		mounted() {
            if (this.options) {
	            this.choices = initChoices(this.$refs.select).setChoices(this.options, 'value', 'label', false).setChoiceByValue(this.modelValue);
            } else {
	            this.choices = initChoices(this.$refs.select).setChoiceByValue(this.modelValue);
            }
	    },
        watch: {
            modelValue(newValue) {
                this.choices.setChoiceByValue(newValue);
            }
        }
	}
</script>
