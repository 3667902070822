<template>
    <div class="dd">
        <ol class="dd-list">
            <menu-builder-item v-for="item in menuBuilder" :key="item.id" :item="item" :pages="pages"></menu-builder-item>
        </ol>
    </div>
    <button class="btn btn-success" @click.prevent="addItem()"><i class="fas fa-plus me-2"></i>Nieuw item</button>
</template>

<script>
import MenuBuilderItem from "./MenuBuilderItem";
export default {
    components: {
        MenuBuilderItem
    },
    props: ['menu', 'pages'],
    data() {
        return {
            menuBuilder: this.menu,
            addedItems: 1,
        };
    },
    methods: {
        addItem() {
            this.menuBuilder.push({
                classes: null,
                id: 'cloned'+this.addedItems,
                name: 'Nieuw item',
                page_id: 1,
                url: ''
            })
            this.addedItems++;
        },
    },
    mounted() {
        const vm = this;
        if (vm.menu.length == 0) {
            vm.addItem();
        }
        $('.dd').nestable();
    },
};
</script>