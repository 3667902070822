import '@eonasdan/tempus-dominus/dist/css/tempus-dominus.css';
import { TempusDominus } from '@eonasdan/tempus-dominus';

var datepickers = document.getElementsByClassName('datepicker');
for (var i = 0; i < datepickers.length; i++) {
    new TempusDominus(datepickers.item(i), {
        localization: {
            format: 'dd-MM-yyyy',
            locale: 'nl',
        },
        useCurrent: false,
        display: {
            viewMode: 'calendar',
            components: {
                hours: false,
                minutes: false,
                seconds: false
            },
            theme: 'light'
        },
    });
}

var datetimepickers = document.getElementsByClassName('datetimepicker');
for (var i = 0; i < datetimepickers.length; i++) {
    new TempusDominus(datetimepickers.item(i), {
        localization: {
            format: 'dd-MM-yyyy HH:mm:00',
            locale: 'nl',
        },
        useCurrent: false,
        display: {
            theme: 'light'
        },
    });
}