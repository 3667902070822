<template>
    <li class="dd-item" :data-id="itemkey">
        <div class="dd-handle"><i class="fa-solid fa-up-down-left-right"></i></div>
        <div class="dd-content">
            <div class="dd-content__header collapsed" data-bs-toggle="collapse" :data-bs-target="'#collapse'+itemkey" aria-expanded="false" :aria-controls="'collapse'+itemkey">
                <span id="title">{{ form.name ?? 'Nieuw menu item' }}</span>
                <i class="fa-solid fa-chevron-right arrow"></i>
            </div>
            

            <div class="collapse" :id="'collapse'+itemkey">
                <div class="row mb-3">
                    <div class="col-6">
                        <label>Naam</label>
                        <input type="text" class="form-control" :name="'menu_item['+itemkey+'][name]'" v-model="form.name">
                    </div>
                    <div class="col-6">
                        <label>Pagina</label>
                        <choices-select :name="'menu_item['+itemkey+'][page_id]'" v-model="form.page_id">
                            <option v-for="page,id in pages" :value="id" :key="id" :selected="form.page_id == id">{{ page }}</option>
                        </choices-select>
                    </div>
                    <div class="col-6">
                        <label>Openen op</label>
                        <choices-select :name="'menu_item['+itemkey+'][target]'" v-model="form.target">
                            <option value="_self">Huidig tabblad</option>
                            <option value="_blank">Nieuw tabblad</option>
                        </choices-select>
                    </div>
                    <div class="col-6" v-if="!form.page_id || isNaN(form.page_id)">
                        <label>URL</label>
                        <input type="text" class="form-control" :name="'menu_item['+itemkey+'][url]'" v-model="form.url">
                    </div>
                    <div class="col-12">
                        <label>Classes</label>
                        <input type="text" class="form-control" :name="'menu_item['+itemkey+'][classes]'" v-model="form.classes">
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <button class="float-end btn btn-danger delete-button" type="button">Verwijder</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- children -->
        <ol class="dd-list" v-if="item.children && item.children.length > 0">
            <menu-builder-item  v-for="item in item.children" :key="item.id" :item="item" :pages="pages"></menu-builder-item>
        </ol>

    </li>
</template>

<script>
import { ref } from 'vue';
import ChoicesSelect from "./ChoicesSelect";
export default {
    name: 'menu-builder-item',
    components: {
        ChoicesSelect
    },
    props: ['item', 'pages'],
    data() {
        return {
            itemkey: this.item.id ?? 'cloned0',
            form: {},
        };
    },
    mounted() {
        this.form = {
            name: this.item.name,
            page_id: '' + this.item.page_id,
            target: this.item.target,
            url: this.item.url,
            classes: this.item.classes
        };
    }
};
</script>