import './plugins/notify.min.js';
import './plugins/tempus_dominus.js';
import Sortable from 'sortablejs';
import Swal from 'sweetalert2';
import DataTable from 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import Choices from 'choices.js';
import AWN from "awesome-notifications";
window.notifier = new AWN({
    labels: {
        success: 'Succes!',
        warning: 'Attentie!',
        alert: 'Error!',
        info: 'Info'
    }
});
window.Sortable = Sortable;
window.Swal = Swal;
window.DataTable = DataTable;
window.Choices = Choices;

window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {

    window.Popper = require('@popperjs/core').default;
    window.$ = window.jQuery = require('jquery');
    require('./plugins/jquery.nestable.min.js');
    // require('nestable2');
    window.Moment = require('moment');

} catch (e) {}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

/**
 * Require scripts
 */
window.bootstrap = require('bootstrap');
