// import { createApp } from 'vue/dist/vue.esm-bundler';
import { createApp } from 'vue';
const app = createApp({});

app.config.globalProperties.$filters = {
    stripHTML(value) {
        const div = document.createElement('div')
        div.innerHTML = value
        const text = div.textContent || div.innerText || ''
        return text
    }
}

app.directive('tooltip', {
    mounted(el, binding) {  
        el.setAttribute('data-toggle', 'tooltip')
        
        new bootstrap.Tooltip(el,{
            title: binding.value ?? el.title,
            placement: binding.arg,
            trigger: 'hover'
        });
    }
})

// components
import menuBuilder from "./components/MenuBuilder.vue";
app.component('menu-builder', menuBuilder);

// NOTE - activate these to use vue devtools
// app.config.productionTip = false;
// app.config.devtools = true;

app.mount('#app');